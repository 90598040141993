import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Grid, IconButton, Typography } from "@mui/material";
import { FieldArray, Formik, FormikProps } from "formik";
import { t } from "i18next";
import React, { useRef } from "react";
import toast from "react-hot-toast";
import { CommonDialog } from "../../components/CommonDialog";
import { FileUpload } from "../../components/FileUpload";
import { TextFieldWrapper } from "../../components/FormElements/TextFieldWrapper";
import { FormDivider } from "../../components/styled/FormDivider";
import {
  IVehicle,
  updateVehicleDocumentsMethod,
  VEHICLE_DOCUMENTS
} from "../../services/vehicle-service";
import { getFileName } from "../../utils";
interface IVehicleDetailsProps {
  open: boolean;
  data?: IVehicle;
  handleToggleOpen: () => void;
}
export const AddDocuments: React.FC<IVehicleDetailsProps> = ({
  handleToggleOpen,
  open,
  data,
}) => {
  const formRef = useRef<FormikProps<any>>(null);
  const dialogClose = () => {
    handleToggleOpen();
  };
  const handleFormSubmit = () => {
    const { current: form } = formRef;
    form?.validateForm();
    if (!form?.isValid) {
      form?.setErrors(form?.errors);
      return;
    }
    formRef.current?.handleSubmit();
  };
  const handleSubmit = async (values: any) => {
    if (!data?.id) {
      return;
    }

    toast.promise(updateVehicleDocumentsMethod(data?.id, values), {
      loading: "Loading...",
      success: (res) => {
        dialogClose();
        return `Successfully uploaded`;
      },
      error: ({ response }) => {
        return "Error when uploading documents";
      },
    });
  };

  return (
    <CommonDialog
      title={t("Add your documents")}
      open={open}
      handleSubmit={handleFormSubmit}
      handleToggleOpen={dialogClose}
    >
        <Grid container spacing={4}>         
          <Grid item xs={12} md={12}>
                <Formik
                  innerRef={formRef}
                  initialValues={{
                    ...data,
                    otherFiles: data?.otherDocuments ? Object.keys(data.otherDocuments).map((doc: any) => {
                      return {
                        name: doc,
                        file:
                          data.otherDocuments &&
                          data.otherDocuments[doc].link,
                      };
                    }) : [{ name: "", file: "" }]
                  }}
                  enableReinitialize
                  onSubmit={async (values: any) => {
                    handleSubmit({
                      ...values,
                    });
                  }}
                >
                  {({ values, handleChange, handleBlur, setFieldValue }) => (
                    <>
                          <Typography>{t("Bill of sale")}</Typography>
                          <FileUpload
                            onFileSelect={(file: any) =>
                              setFieldValue(VEHICLE_DOCUMENTS.INVOICE, file)
                            }
                            onDelete={() => setFieldValue(VEHICLE_DOCUMENTS.INVOICE, null)}
                            value={values?.invoice}
                            label={getFileName(values?.invoice) ?? t("Bill of sale")}
                          />
                          <Typography>{t("Power of Attorney")}</Typography>
                          <FileUpload
                            onFileSelect={(file: any) =>
                              setFieldValue(VEHICLE_DOCUMENTS.POA, file)
                            }
                            onDelete={() => setFieldValue(VEHICLE_DOCUMENTS.POA, null)}
                            value={values?.powerOfAttorney}
                            label={getFileName(values?.powerOfAttorney) ?? t("Power of Attorney")}
                          />
                          <Typography>{t("Shipping invoice")}</Typography>
                          <FileUpload
                            onFileSelect={(file: any) =>
                              setFieldValue(VEHICLE_DOCUMENTS.SHIPPING_INVOICE, file)
                            }
                            onDelete={() => setFieldValue(VEHICLE_DOCUMENTS.SHIPPING_INVOICE, null)}
                            value={values.shippingInvoice}
                            label={getFileName(values.shippingInvoice) ?? t("Shipping invoice")}
                          />
                          <Typography>{t("Title")}</Typography>
                          <FileUpload
                            onFileSelect={(file: any) =>
                              setFieldValue(VEHICLE_DOCUMENTS.TITLE, file)
                            }
                            onDelete={() => setFieldValue(VEHICLE_DOCUMENTS.TITLE, null)}
                            value={values?.title}
                            label={getFileName(values?.title) ?? t("Title")}
                          />
                          <Typography>{t("Personal ID")}</Typography>
                          <FileUpload
                            onFileSelect={(file: any) =>
                              setFieldValue(VEHICLE_DOCUMENTS.PERSONAL_ID, file)
                            }
                            onDelete={() => setFieldValue(VEHICLE_DOCUMENTS.PERSONAL_ID, null)}
                            value={values?.personalId}
                            label={getFileName(values?.personalId) ?? t("Personal ID")}
                          />
                          <Typography>{t("Payment proof")}</Typography>
                          <FileUpload
                            onFileSelect={(file: any) =>
                              setFieldValue(VEHICLE_DOCUMENTS.PAYMENT_PROOF, file)
                            }
                            onDelete={() => setFieldValue(VEHICLE_DOCUMENTS.PAYMENT_PROOF, null)}
                            value={values?.paymentProof}
                            label={getFileName(values?.paymentProof) ?? t("Payment proof")}
                          />
                          <FormDivider variant="fullWidth">{t("Other documents")}</FormDivider>
                                <FieldArray
                                name="otherFiles"
                                render={(arrayHelpers) => (
                                  <div>
                                    {values.otherFiles?.map((status: any, index: number) => {
                                      const name = Object.keys(values.otherFiles[index] );
                                      return (
                                        <Grid
                                        style={{ paddingBottom: 10 }}
                                        gap={1}
                                        container
                                        key={index}
                                      >
                                        {/** both these conventions do the same */}
                                        <Grid item xs={12} md={3}>
                                          <TextFieldWrapper
                                            label={t("Document name")}
                                            fullWidth
                                            size="small"
                                            value={
                                              values.otherFiles[index]
                                                ? values.otherFiles[index].name
                                                : ""
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name={`otherFiles[${index}].name`}
                                          />
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                          <FileUpload
                                            onFileSelect={(file: any) =>
                                              setFieldValue(`otherFiles[${index}].file`, file)
                                            }
                                            onDelete={() =>
                                              setFieldValue(`otherFiles[${index}].file`, null)
                                            }
                                            dialogTitle={t("Select file")}
                                            value={
                                              values.otherFiles[index]
                                                ? values.otherFiles[index].file
                                                : null
                                            }
                                            label={
                                              values.otherFiles[index] &&
                                              values.otherFiles[index].file
                                                ? getFileName(values.otherFiles[index].file)
                                                : t("Select file")
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                          {index > 0 && (
                                            <IconButton
                                              type="button"
                                              onClick={() => arrayHelpers.remove(index)}
                                            >
                                              <RemoveIcon fontSize="medium" />
                                            </IconButton>
                                          )}
                                          {index + 1 === values?.otherFiles.length && values?.otherFiles.length < 5 && (
                                            <IconButton
                                              type="button"
                                              onClick={() =>
                                                arrayHelpers.push({
                                                  status: "",
                                                })
                                              }
                                            >
                                              <AddIcon fontSize="medium" />
                                            </IconButton>
                                          )}
                                        </Grid>
                                      </Grid>
                                      )
                                    })}
                                  </div>
                                )}
                              />
                              </>
                  )}
                    </Formik>
          </Grid>
        </Grid>
    </CommonDialog>
  );
};
